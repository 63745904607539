import {GET_CHECKLIST} from "../constants/Constants";

export const CheckListReducer = (
  state = { checkList: [], },
  action
) => {
  switch (action.type) {

    case GET_CHECKLIST:
      return { ...state, checkList:action.payload };

    default:
      return state;
  }
};
