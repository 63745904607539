import { GET_ANALYTIC} from "../constants/Constants";

export const AnalyticReducer = (state={}, action) => {
  switch (action.type) {
    case GET_ANALYTIC:
      return {  analytics: action.payload }
    default:
      return state;
  }
};
