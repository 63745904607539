import { GET_BlogData} from "../constants/Constants";

export const BlogReducer = (state={}, action) => {
  switch (action.type) {
    case GET_BlogData:
      return {  blogdata: action.payload }
    default:
      return state;
  }
};
