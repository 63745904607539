import { GET_VINOCR} from "../constants/Constants";

export const AnalyticsOCR = (state={}, action) => {
  switch (action.type) {
    case GET_VINOCR:
      return {  analyticOCR: action.payload }
    default:
      return state;
  }
};
