import { GET_PROMOCODE } from "../constants/Constants";

export const promoCodeListReducer = (state = { promoCodeList: [] }, action) => {
  switch (action.type) {
    case GET_PROMOCODE:
      return { ...state, promoCodeList: action.payload };

    default:
      return state;
  }
};
