import {
   GET_SURVEY,
} from "../constants/Constants";

export const SurveyReducer = (state={surveys:[]}, action) => {
  switch (action.type) {
    case GET_SURVEY:
      return {  surveys: action.payload }
    default:
      return state;
  }
};
