import {
  GET_ADMINS, GET_COMPANY_FOR_ADMIN, GET_ALL_ASSIGNEES
} from "../constants/Constants";

export const AdminReducer = (state={admins:[]}, action) => {
  switch (action.type) {
    case GET_ADMINS:
      return {  admins: action.payload }
    default:
      return state;
  }
};
export const CompanyForAdminReducer = (state={companiesAdmin:[]}, action) => {
  switch (action.type) {
    case GET_COMPANY_FOR_ADMIN:
      return {  companiesAdmin: action.payload }
    default:
      return state;
  }
};
export const AssigneesListReducer = (state={AssigneesList:[]}, action) => {
  switch (action.type) {
    case GET_ALL_ASSIGNEES:
      return {  AssigneesList: action.payload }
    default:
      return state;
  }
};
