import {
    GET_USERS_DATA
  } from "../constants/Constants";

  export const usersReducer = (state={users:{}}, action) => {
    switch (action.type) {
      case GET_USERS_DATA:
        return { users: action.payload }
      default:
        return state;
    }
  };