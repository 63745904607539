import {GET_PROFILE} from "../constants/Constants";

export const ProfileReducer = (state={}, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {  profile: action.payload }
    default:
      return state;
  }
};
