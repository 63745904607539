import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";
import { AuthReducer } from "./redux/reducers/Auth";
import {VehicleReducer, VinReducer} from "./redux/reducers/vehicle";
import { AdminReducer, CompanyForAdminReducer, AssigneesListReducer } from "./redux/reducers/admin";
import { SurveyReducer } from "./redux/reducers/survey";
import { CompanyReducer, TemplateReducer } from "./redux/reducers/company";
import { ProfileReducer } from "./redux/reducers/profile";
import { usersReducer } from "./redux/reducers/user";
import {AnalyticReducer} from "./redux/reducers/analytic";
import {CheckListReducer} from "./redux/reducers/CheckList";
import {InspectionAnalyticReducer} from "./redux/reducers/InspectionAnalytic";
import {AnalyticsOCR} from "./redux/reducers/AnalyticsOCR";
import {BlogReducer} from "./redux/reducers/blog";
import {promoCodeListReducer} from "./redux/reducers/promocode"

const middleware = [thunk];
const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
};
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};
const reducer = combineReducers({
  nav: changeState,
  auth: AuthReducer,
  getVehicle: VehicleReducer,
  getData: CheckListReducer,
  getAdmin: AdminReducer,
  getSurvey: SurveyReducer,
  getCompany: CompanyReducer,
  getTemplate: TemplateReducer,
  getCompanyForAdmin: CompanyForAdminReducer,
  getAssignees: AssigneesListReducer,
  getProfile: ProfileReducer,
  getUsers: usersReducer,
  getAnalytic: AnalyticReducer,
  getInspectionAnalytic:InspectionAnalyticReducer,
  getVinVehicle: VinReducer,
  getBlogdata: BlogReducer,
  getAnalyticOCR: AnalyticsOCR,
  getCheckList: promoCodeListReducer
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
