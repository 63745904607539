import {
  GET_VEHICLES,
  GET_CHECKLIST,
  GET_VIN_DETAIL,
  // GET_VIN_DETAIL_FAIL,
  SET_VEHICLE_DETAIL,
  SET_VEHICLE_FILES,
  SET_VEHICLE_FILE,
} from "../constants/Constants";

const vehicleFiles = [
  {
    category: "registration_card",
    title: "Registration Card",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    loading: false,
    fileId: "",
    dateImage: null,
    status: null,
    statusAi: null,
    commentAi: null,
    comments: null,
    inspectionId: null,
    InspectionChecks: [],
    type: "image",
  },
  {
    category: "odometer",
    title: "Odometer",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    url: "",
    fileId: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "verification_card",
    title: "Drivers License Verification",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    fileId: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "horn",
    title: "Horn",
    url: "",
    extension: "video/mp4",
    type: "video",
    groupType: "interiorItems",
    fileId: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "interior_driver_side",
    title: "Interior Driver Side",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "driver_seat",
    title: "Driver Seat Adj.",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    fileId: "",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "interior_passenger_side",
    title: "Interior Passenger Side",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "passenger_seat",
    title: "Passenger seat Adj.",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "interior_backseat",
    title: "Interior Backseat",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    fileId: "",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_left",
    title: "Exterior Left",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_right",
    title: "Exterior Right",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_front",
    title: "Exterior Front",
    url: "",
    extension: "video/mp4",
    groupType: "exteriorItems",
    fileId: "",
    type: "video",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    comments: null,
    commentAi: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_rear",
    title: "Exterior Rear",
    url: "",
    extension: "video/mp4",
    groupType: "exteriorItems",
    fileId: "",
    type: "video",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    breakMeasurements: null,
  },
  {
    category: "left_front_tire",
    title: "Left Front Tire",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "tires",
    fileId: "",
    dimension: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "left_rear_tire",
    title: "Left Rear Tire",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "tires",
    dimension: "",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "right_front_tire",
    title: "Right Front Tire",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "tires",
    dimension: "",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "right_rear_tire",
    title: "Right Rear Tire",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "tires",
    dimension: "",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
];
const vehicleFile = [
  {
    category: "registration_card",
    title: "Registration Card",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    loading: false,
    fileId: "",
    dateImage: null,
    status: null,
    statusAi: null,
    commentAi: null,
    comments: null,
    inspectionId: null,
    InspectionChecks: [],
    type: "image",
  },
  {
    category: "odometer",
    title: "Odometer",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    url: "",
    fileId: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "verification_card",
    title: "Drivers License Verification",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    fileId: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "horn",
    title: "Horn",
    url: "",
    extension: "video/mp4",
    type: "video",
    groupType: "interiorItems",
    fileId: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "interior_driver_side",
    title: "Interior Driver Side",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "driver_seat",
    title: "Driver Seat Adj.",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    fileId: "",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "interior_passenger_side",
    title: "Interior Passenger Side",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "passenger_seat",
    title: "Passenger seat Adj.",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "interior_backseat",
    title: "Interior Backseat",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "interiorItems",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    fileId: "",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_left",
    title: "Exterior Left",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_right",
    title: "Exterior Right",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_front",
    title: "Exterior Front",
    url: "",
    extension: "video/mp4",
    groupType: "exteriorItems",
    fileId: "",
    type: "video",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    comments: null,
    commentAi: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_rear",
    title: "Exterior Rear",
    url: "",
    extension: "video/mp4",
    groupType: "exteriorItems",
    fileId: "",
    type: "video",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    breakMeasurements: null,
  },
  {
    category: "tire",
    title: "Tire",
    url: "",
    extension: "video/mp4",
    orientation: "",
    groupType: "tire",
    dimension: "",
    fileId: "",
    type: "video",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
];

export const VehicleReducer = (
  state = { vehicles: [], vehicleDetail: [], vehicleFiles, vehicleFile },
  action
) => {
  switch (action.type) {
    case GET_VEHICLES:
      return { ...state, vehicles: action.payload };
    // case GET_CHECKLIST:
    //   return { ...state, vehicles:action.payload };
    case SET_VEHICLE_DETAIL:
      return { ...state, vehicleDetail: action.payload };
    case SET_VEHICLE_FILES:
      state?.vehicleFiles?.forEach((a) => {
        var item = action?.payload?.find((x) => a.category === x.category);
        if (item) {
          a.url = item.url;
          a.InspectionChecks = item.InspectionChecks;
          a.status = item?.isReviewed;
          a.dateImage = item?.dateImage;
          a.orientation = item?.orientation;
          a.fileId = item?.id;
          a.inspectionId = item?.inspectionId;
          a.statusAi = item?.statusAi;
          a.comments = item?.comments;
          a.commentAi = item?.commentAi;
          if (a.groupType === "tires") {
            a.dimension = item?.dimension;
            a.longitude = item?.longitude;
            a.latitude = item?.latitude;
          }
          if (a.category === "exterior_rear") {
            a.breakMeasurements = item?.breakMeasurements;
          }
        } else {
          a.url = "";
          a.InspectionChecks = [];
          a.status = null;
          a.dateImage = "";
          a.orientation = "";
          a.statusAi = null;
          a.comments = null;
          a.commentAi = null;
          a.fileId = "";
          a.inspectionId = null;
          if (a.groupType === "tires") {
            a.dimension = "";
          }
          if (a.category === "exterior_rear") {
            a.breakMeasurements = "";
          }
        }
      });
      return { ...state };
    case SET_VEHICLE_FILE:
      state?.vehicleFile?.forEach((a) => {
        var item = action?.payload?.find((x) => a.category === x.category);
        if (item) {
          a.url = item.url;
          a.InspectionChecks = item.InspectionChecks;
          a.status = item?.isReviewed;
          a.dateImage = item?.dateImage;
          a.orientation = item?.orientation;
          a.fileId = item?.id;
          a.inspectionId = item?.inspectionId;
          a.statusAi = item?.statusAi;
          a.comments = item?.comments;
          a.commentAi = item?.commentAi;
          if (a.groupType === "tire") {
            a.dimension = item?.dimension;
            // a.longitude = item?.longitude;
            // a.latitude = item?.latitude;
          }
          if (a.category === "exterior_rear") {
            a.breakMeasurements = item?.breakMeasurements;
          }
        } else {
          a.url = "";
          a.InspectionChecks = [];
          a.status = null;
          a.dateImage = "";
          a.orientation = "";
          a.statusAi = null;
          a.comments = null;
          a.commentAi = null;
          a.fileId = "";
          a.inspectionId = null;
          if (a.groupType === "tire") {
            a.dimension = "";
          }
          if (a.category === "exterior_rear") {
            a.breakMeasurements = "";
          }
        }
      });
      return { ...state };

    default:
      return state;
  }
};

const transformVinDetails = (data) => {
  const transformationObject = {};
  data.forEach((obj) => {
    if (obj.label === "VIN") {
      transformationObject.vin = obj.value;
    }
    if (obj.label === "Make") {
      transformationObject.make = obj.value;
    }
    if (obj.label === "Model Year") {
      transformationObject.year = obj.value;
    }
    if (obj.label === "Model") {
      transformationObject.model = obj.value;
    }
  });
  return transformationObject;
};

export const VinReducer = (state = { vehicleVin: {} }, action) => {
  switch (action.type) {
    case GET_VIN_DETAIL:
      return { vehicleVin: transformVinDetails(action.payload) };
    default:
      return state;
  }
};
