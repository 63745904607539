import {
  GET_COMPANIES, GET_TEMPLATE, GET_COMPANY_DATA,
} from "../constants/Constants";

export const CompanyReducer = (state={companies:[], companyData:[] }, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {  companies: action.payload }
    case GET_COMPANY_DATA:
      return {  companyData: action.payload }
    default:
      return state;
  }
};

export const TemplateReducer = (state={templates:[]}, action) => {
  switch (action.type) {
    case GET_TEMPLATE:
      return {  templates: action.payload }
    default:
      return state;
  }
};

// export const CompanyDataReducer = (state={companyData:[]}, action) => {
//   switch (action.type) {
//     case GET_COMPANY_DATA:
//       return {  companyData: action.payload }
//     default:
//       return state;
//   }
// };